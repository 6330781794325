@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Inter/inter-regular.eot);
    src: local('Inter'),
    url(/fonts/Inter/inter-regular.eot?#iefix) format('embedded-opentype'),
    url(/fonts/Inter/inter-regular.woff2) format('woff2'),
    url(/fonts/Inter/inter-regular.woff) format('woff'),
    url(/fonts/Inter/inter-regular.ttf) format('truetype'),
    url(/fonts/Inter/inter-regular.svg#Inter) format('svg');
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Outfit/outfit-regular.eot);
    src: local('Outfit'),
    url(/fonts/Outfit/outfit-regular.eot?#iefix) format('embedded-opentype'),
    url(/fonts/Outfit/outfit-regular.woff2) format('woff2'),
    url(/fonts/Outfit/outfit-regular.woff) format('woff'),
    url(/fonts/Outfit/outfit-regular.ttf) format('truetype'),
    url(/fonts/Outfit/outfit-regular.svg#Outfit) format('svg');
    font-display: swap;
}
@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/Outfit/outfit-500.eot);
    src: local('Outfit'),
    url(/fonts/Outfit/outfit-500.eot?#iefix) format('embedded-opentype'),
    url(/fonts/Outfit/outfit-500.woff2) format('woff2'),
    url(/fonts/Outfit/outfit-500.woff) format('woff'),
    url(/fonts/Outfit/outfit-500.ttf) format('truetype'),
    url(/fonts/Outfit/outfit-500.svg#Outfit) format('svg');
    font-display: swap;
}
@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/Outfit/outfit-600.eot);
    src: local('Outfit'),
    url(/fonts/Outfit/outfit-600.eot?#iefix) format('embedded-opentype'),
    url(/fonts/Outfit/outfit-600.woff2) format('woff2'),
    url(/fonts/Outfit/outfit-600.woff) format('woff'),
    url(/fonts/Outfit/outfit-600.ttf) format('truetype'),
    url(/fonts/Outfit/outfit-600.svg#Outfit) format('svg');
    font-display: swap;
}
