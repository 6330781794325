@tailwind base;
@tailwind components;
@tailwind utilities;

/* react-elastic-carousel */
.rec-slider-container {
  margin: 0 !important;
  overflow: visible !important;

}

.rec-item-wrapper {
  height: 100%;
  overflow: visible !important;
}

.items-elastic .rec-item-wrapper {
  width: auto !important;
}

.rec-carousel {
  min-height: 550px !important;
}

.category-list .rec-carousel,
.products-list .rec-carousel {
  min-height: auto !important;
}

@media only screen and (max-width: 1023px) {
  .rec-carousel {
    min-height: 440px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rec-carousel {
    min-height: 400px !important;
  }
}

@media only screen and (max-width: 479px) {
  .rec-carousel {
    min-height: 320px !important;
  }
}

@layer components {
  #__next {
    @apply min-h-screen;
  }
  html,
  body {
    @apply min-h-screen antialiased;
  }

  .hidden {
    display: none;
  }

  .btn-black {
    @apply flex flex-nowrap items-center justify-center rounded-full bg-black px-3 pb-[9px] pt-[8px] text-center text-button font-medium text-white hover:drop-shadow-lg md:px-6;
  }

  .btn-white {
    @apply flex flex-nowrap items-center justify-center rounded-full bg-white px-3 pb-[9px] pt-[8px] text-center text-button font-medium text-black hover:drop-shadow-lg md:px-6;
  }

  .btn-outline {
    @apply flex flex-nowrap items-center justify-center rounded-full px-3 pb-[9px] pt-[8px] text-center text-button font-medium text-black ring-1 ring-inset ring-black hover:drop-shadow-lg md:px-6;
  }

  .btn-white-outline {
    @apply flex flex-nowrap items-center justify-center rounded-full px-3 pb-[9px] pt-[8px] text-center text-button font-medium text-white ring-1 ring-inset ring-white hover:drop-shadow-lg md:px-6;
  }

  .btn-text {
    @apply flex flex-nowrap items-center justify-center rounded-full px-3 pb-[9px] pt-[8px] text-center text-button font-medium text-black md:px-6;
  }

  .btn-large {
    @apply min-h-[60px] px-5 md:px-8 md:text-base;
  }

  .btn-icon {
    @apply flex h-8 w-8 items-center justify-center rounded-full text-button;
  }

  .btn-black-icon {
    @apply flex h-8 w-8 items-center justify-center rounded-full bg-black text-button text-white;
  }

  .btn-white-icon {
    @apply flex h-8 w-8 items-center justify-center rounded-full bg-white text-button text-black;
  }

  .btn-white-icon-outline {
    @apply flex h-8 w-8 items-center justify-center rounded-full bg-white text-button text-black text-opacity-20 ring-1 ring-inset ring-black ring-opacity-10;
  }

  .btn-icon-md {
    @apply h-11 w-11;
  }
  .btn-icon-lg {
    @apply h-14 w-14;
  }

  svg.spinner {
    animation: 2s linear infinite svg-animation;
    max-width: 100px;
  }
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }
  circle.spinner {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }
  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    
    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
  .about-block {
    opacity: 0;
  }
  .about-block.animate {
    animation: about-animation 1s linear 1 forwards;
  }
  .about-block.animate:nth-child(1) {
    animation-delay: .2s;
  }
  .about-block.animate:nth-child(2) {
    animation-delay: .4s;
  }
  .about-block.animate:nth-child(3) {
    animation-delay: .6s;
  }
  @keyframes about-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .top-numbers {
    opacity: 0;
  }
  .top-numbers.animate {
    animation: top-numbers-animation .3s linear 1 forwards;
  }
  .top-number-2.animate {
    animation-delay: .2s;
  }
  .top-number-3.animate {
    animation-delay: .3s;
  }
  .top-number-4.animate {
    animation-delay: .4s;
  }
  .top-number-5.animate {
    animation-delay: .5s;
  }
  .top-number-6.animate {
    animation-delay: .6s;
  }
  .top-number-7.animate {
    animation-delay: .7s;
  }
  .top-number-8.animate {
    animation-delay: .8s;
  }
  .top-number-9.animate {
    animation-delay: .9s;
  }
  .top-number-10.animate {
    animation-delay: 1s;
  }
  @keyframes top-numbers-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@layer utilities {
  .heading-big {
    @apply font-sans text-h3 font-semibold md:text-h1 lg:text-big;
  }
  .heading-h1 {
    @apply font-sans text-h2 font-semibold md:text-h1;
  }
  .heading-h2 {
    @apply font-sans text-h3 font-semibold md:text-h2;
  }
  .heading-h3 {
    @apply font-sans text-h4 font-semibold md:text-h3;
  }
  .heading-h4 {
    @apply font-sans text-base font-semibold md:text-h4;
  }
  .heading-preamble {
    @apply font-sans text-semiSmall md:text-base lg:text-preamble;
  }

  .dropshadow {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    transform: translateZ(0);
  }
  .btn-shadow {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }
  .card-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) -8.54%, rgba(0, 0, 0, 0) 23.6%),
      linear-gradient(180.01deg, rgba(0, 0, 0, 0) 60.67%, rgba(0, 0, 0, 0.7) 90.89%);
    background-blend-mode: normal, multiply;
  }
  .card-gradient-hover {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 29.66%),
      linear-gradient(180.01deg, rgba(0, 0, 0, 0) 54.93%, rgba(0, 0, 0, 0.9) 98.47%);
    background-blend-mode: normal, multiply;
  }
  .hero-gradient {
    background: linear-gradient(226.24deg, rgba(0, 0, 0, 0) 52.67%, rgba(0, 0, 0, 0.8) 98.29%);
  }

  .text-link {
    @apply hover:underline decoration-2 underline-offset-2;
  }

  .undraggable, .undraggable * {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .break-words {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
  input {
    -webkit-appearance: none;
  }

  .safari-14_1-fallback {
    /* @supports not (aspect-ratio: auto) {  */
      height: 100px;
      /* position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: auto;
      max-width: 100%; 
      height: auto; */
    /* } */
  }

  #vimeo-video iframe {
    @apply xl:mx-auto;
  }

  iframe#main-vimeo-video {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}
